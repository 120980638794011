import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon } from '@wam/icons';

@Component({
  selector: 'cl-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpButtonComponent {
  @Input() icon = Icon.question;
  flagModal = false;
  @Input() expandTo: 'top' | 'right' | 'bottom' | 'left' = 'right';
  @Input() modalWidth = '228px';

  showModal() {
    this.flagModal = !this.flagModal;
  }
}
