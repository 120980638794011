import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@wam/icons';

import { HelpButtonComponent } from './help-button.component';

@NgModule({
  declarations: [HelpButtonComponent],
  imports: [CommonModule, IconModule],
  exports: [HelpButtonComponent],
})
export class HelpButtonModule {}
