<div class="helpBtnContainer">
  <cl-icon
    [icon]="icon"
    (click)="showModal()"
    [size]="'40px'"></cl-icon>
  <div
    *ngIf="flagModal"
    class="helpBtnContainer__overlay"
    (click)="showModal()"></div>
  <div
    *ngIf="flagModal"
    class="helpBtnContainer__modal"
    [ngClass]="expandTo"
    [style.--modal-width]="modalWidth">
    <ng-content select="[help]"></ng-content>
  </div>
</div>
